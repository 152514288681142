/* Profile Menu */

.wrapper {
  position: absolute;
  background-color: white;
  z-index: 200;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  top: 65px;
  right: 0;
  border: 0.5px solid rgba(128, 128, 128, 0.123);
  height: auto;
  width: 282px;
}

.large {
  width: 400px;
}

.profileNavMenuItemTop {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding: 0.75rem 0.75rem;

  &:hover {
    background-color: var(--primary-bg);
    cursor: pointer;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-wrap: break-word;
  width: 100%;
}

.profilePic {
  border: 2px solid var(--primary-bg);
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 100px;
}

.largeText {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-main);
  margin-bottom: 0;

  /* ellipse after 2 lines */

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.smallText {
  margin-bottom: 0rem;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-mute);
}

.sectionText {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-main);
  margin-bottom: 0;
  padding-left: 1rem;
}

.navMenuItem {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    background-color: var(--primary-bg);
    cursor: pointer;
  }
}

.dropDownContent {
  border-top: 1px solid var(--primary-bg);
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 1rem;
  padding-top: 1rem;
  color: var(--text-body);
}

.separator {
  width: 1px;
  background-color: var(--primary-border);
}

/* cart Modal */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-bottom: 1px solid var(--primary-border);
  font-size: 14px;
}

.cartMenuItem {
  position: relative;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding: 0.75rem 0.75rem;
  padding-right: 1.5rem;

  &:hover {
    background-color: var(--primary-bg-light);
    cursor: pointer;
  }
}

.border {
  border-bottom: 1px solid var(--primary-border);
}

.overflow {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
}

.listingImage {
  height: 50px;
  min-height: 50px;
  width: 50px;
  min-width: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.delete {
  color: var(--text-body);
  position: absolute;
  right: 5px;
  width: 1em;
  height: 1em;
}

.delete:hover {
  transform: scale(1.1);
}

.buttonWrapper {
  display: flex;
  padding: 0.75rem;
  border-top: 1px solid var(--primary-border);
}

.fullWidth {
  width: 100%;
}

/* Placeholders */
.placeholderBox {
  width: 50px !important;
  height: 50px !important;
  min-width: 50px;
  min-height: 50px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

.textPlaceholderLong {
  width: 80%;
  height: 15px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
  margin-bottom: 5px;
}

.textPlaceholderShort {
  width: 40%;
  height: 15px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

@keyframes anim {
  0%,
  100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
}

/* notification */

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-purple);
  color: white;
  height: 1.5em;
  width: 1.5em;
  padding: 4px;
  border-radius: 100px;
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.iconNot {
  width: 80%;
  height: 80%;
}
.purple {
  background-color: var(--accent-purple);
}

.red {
  background-color: var(--accent-red);
}

.green {
  background-color: var(--accent-green);
}

.blue {
  background-color: var(--accent-blue);
}

.yellow {
  background-color: var(--accent-yellow);
}

.beige {
  background-color: var(--accent-beige);
  color: var(--text-main);
}

.boldText {
  color: var(--text-main) !important;
}

.sent {
  display: flex;
  text-align: end;
  align-items: center;
  font-size: 13px;
  color: var(--text-mute) !important;
  white-space: nowrap;
  margin-bottom: 0rem;
  margin-bottom: 0rem;
}

.notificationText {
  font-size: 13px;
  margin-bottom: 0rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 220px;
}

.message {
  display: flex;
  align-items: center;
  color: var(--text-main);

  flex-direction: row;
}

.notificationTextNotSeen {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 220px;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0rem;
  color: var(--text-body);
}

.notificationTextOverflow {
  display: inline-block;
  max-width: 15em;
  height: 1.5em;
  font-weight: 400;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.chatNotification {
  height: 10px;
  width: 10px;
  min-height: 10px;
  min-width: 10px;
  background-color: var(--primary-pink);
  border-radius: 50%;
  display: inline-block;
}

.buySomeShit {
  height: 100%;
  padding: 1rem;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}

.emptyBox {
  width: 70px;
  height: 70px;
}

.buyText {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0;
}

.buyLink {
  color: var(--primary-purple);
  font-size: 14px;
  font-family: "Editorial";
  cursor: pointer;
}

.buyLink:hover {
  opacity: 0.6;
}

@media (max-width: 768px) {
  .wrapper {
    top: 55px;
    width: calc(100% - 2rem);
    right: 1rem;
  }
}
