.main {
  display: flex;
  column-gap: 1rem;
  /* height: 564px; */
}

.leftSection {
  width: 50%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/landing_page/mid_century_mobler_1.JPG"); */
  background-repeat: no-repeat;
  background-size: cover;
  /* let img start from bottom */
  background-position: bottom;
  position: relative;
  border-radius: 3px;
}

.rightSection {
  display: grid;
  width: 50%;
  height: inherit;

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr; /* Add this line to define the two columns */

  row-gap: 1rem;
  column-gap: 1rem;
}

.observer {
  background-color: var(--primary-bg);
  width: 100%;
  height: 100%;
}

.listing {
  background-color: var(--primary-bg);
}

.titleBox {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 300px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  row-gap: 0.5rem;
}

.h2 {
  font-family: "Editorial";
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 0rem;
  text-align: center;
}

.link {
  font-family: "Editorial";
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 0rem;
  color: var(--text-body);

  border-bottom: 0.5px solid var(--text-body);
  display: inline-block;
}

.credit {
  font-size: 12px;
  position: absolute;
  color: white;
  bottom: 1rem;
  left: 1.5rem;
}

.link:hover {
  cursor: pointer;
  color: var(--text-main);
  border-bottom: 0.5px solid var(--text-main);
}

.discoverSection {
  display: grid;
  gap: 0.75rem;
  height: fit-content;
  grid-template-columns: repeat(6, 1fr);
  overflow-x: scroll;
  overflow-y: hidden;
}

.loadingBox {
  width: 100%;
  border-radius: 9999px;
  display: flex;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg);
}
.loadingPlaceholder {
  width: 50%;
  aspect-ratio: 1/1;
  border-radius: 9999px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    /* Lightest shade, almost white but still has contrast */ hsl(0, 0%, 80%)
      50%,
    /* Medium shade for visible contrast */ hsl(0, 0%, 85%) 100%
      /* Light shade that's between the lightest and medium */
  );
  animation: anim 2s linear infinite;
}

.item {
  width: 100%; /* Add a fixed width */
  height: fit-content;

  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  row-gap: 0.5rem;
  height: inherit;
  background-color: white;
}

.item:hover .imgContainer {
  filter: brightness(0.95);
  transition: 0.1s linear;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  border-radius: 9999px;
  padding: 1rem;
  aspect-ratio: 1/1;

  overflow: hidden;
  background-color: var(--primary-bg);
}
.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(1);
}

/* .img:hover {
  cursor: pointer;
  filter: brightness(0.9);
  transition: 0.1s linear;
} */

.text {
  /* position: absolute; */
  color: var(--text-body);
  font-family: "Clean";
  /* text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.9); */
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  /* top: 50%; */
  font-size: 11px;
  font-weight: 400;
  /* transform: translateY(-50%); */
  cursor: pointer;
}

.col {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.row {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header {
  color: var(--text-main);
  font-family: "Editorial";
  margin-bottom: 0rem;
  margin-right: auto;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.seeAll {
  font-size: 18px;
  font-weight: 400;
  font-family: "Editorial";
  color: var(--text-body);
  margin-bottom: 0rem;
  text-decoration: none;
}

@media (max-width: 600px) {
  .main {
    flex-direction: column !important;
    height: fit-content;
    row-gap: 1rem;
  }

  .leftSection {
    width: 100%;
    aspect-ratio: 1/1;
  }

  .rightSection {
    width: 100%;
  }
  .h2 {
    font-size: 18px;
  }

  .header {
    font-size: 18px;
  }

  .titleBox {
    max-width: 60%;
  }

  .seeAll {
    font-size: 16px;
    white-space: nowrap;
  }

  .imgContainer {
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    height: 100px;
  }
}
