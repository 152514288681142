.footer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 5rem 0;
}

@media (max-width: 768px) {
  .footer {
    gap: 30px;
    padding: 3rem 1rem;
  }
}
