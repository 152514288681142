.wrapper {
  max-width: var(--max-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 0.75rem 0rem;
  padding-bottom: 0;
  position: relative;
  justify-content: space-between;
}

/* NEW */

.sectionWrapper {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.links {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.logoSection {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.logoWrapper {
  background-color: var(--primary-purple);
  border-radius: 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoMark {
  object-fit: contain;
}

.logo {
  object-fit: contain;
  height: 20px;
}

.navBubble {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 5px;
  font-weight: 400;
  white-space: nowrap;

  text-decoration: none;
  color: var(--text-main);
  padding: 0.25rem 0.25rem;
  border-radius: 30px;
  height: 100%;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
    color: var(--text-body);
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
}

.buttonModalWrapper {
  height: fit-content;
  width: fit-content;
  height: 100%;
}

.navBubbleBorder {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 0.5rem;
  font-weight: 400;
  white-space: nowrap;

  text-decoration: none;
  color: var(--text-main);
  border: 1px solid var(--primary-border);
  padding: 0.5rem 0.75rem;
  height: 100%;
  border-radius: 30px;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:hover {
    color: var(--text-body);
    opacity: 0.6;
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
}

.active {
  color: var(--primary-purple);
  border: 1px solid var(--primary-pink);
}

.burgerIcon {
  margin-bottom: 5px;
}

.linkIcon {
  width: 20px;
  height: 20px;
}

/* Searchbar */

.searchBar {
  width: 100%;
  height: 45px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;

  position: relative;
}

.iconContainerActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;

  border-radius: 100%;
  position: relative;
  background-color: var(--primary-bg);
}

.iconContainer:hover {
  border-radius: 100%;
  background-color: var(--primary-bg);
}

.icon {
  width: 24px;
  height: 24px;
  color: #222222;
  cursor: pointer;
}

.notificationNumber {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--primary-pink);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #fd589e;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}

@media (max-width: 768px) {
  .wrapper {
    gap: 1rem;
    padding: 0.5rem 1rem;
  }
  .searchBar {
    display: none;
  }

  .linkIcon {
    display: none;
  }

  .visible {
    display: flex;
  }

  .mobileNotVisible {
    display: none;
  }

  .logo {
    display: none;
  }

  .sectionWrapper {
    gap: 0.5rem;
  }

  .logoWrapper {
    width: 46px;
    height: 46px;
  }

  .logoMark {
    width: 50%;
    height: 50%;
  }

  .navBubble {
    padding: 0.25rem 0.25rem;
    height: 100%;
  }

  .navBubbleBorder {
    padding: 0.25rem 0.5rem;
    border: 0;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .links {
    gap: 0.25rem;
  }
}
