.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-family: Editorial;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 0;
}

.link {
  font-size: 18px;
  margin-bottom: 0;
  font-family: Editorial;
  font-weight: 400;
  color: var(--text-body);
  text-decoration: none;
}

.link:hover {
  color: var(--text-main);
}

.valuePropsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.valueProp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.p {
  font-size: 14px;
  font-weight: 300;
  color: var(--text-body);
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .wrapper {
    gap: 30px;
  }
  .valuePropsGrid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .valueProp {
    gap: 5px;
  }

  .title {
    font-size: 18px;
  }

  .link {
    font-size: 16px;
  }
}
