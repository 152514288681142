.reviewCard {
  margin-top: 20px;
}
.reviewContainer {
  /* display: flex;
  justify-content: space-between; */
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
.createReview {
  width: 100%;
  margin-top: 20px;
}
.reviewCardContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.reviewFormContainer {
  display: flex;
  flex-direction: column;
}
.reviewInput {
  margin: 10px !important;
}
.reviewFooterContainer {
  display: flex;
  justify-content: space-between;
}
.reviewFooter {
  display: flex;
}
.reviewRating {
  margin-left: 20px;
}
.pictureLabel {
  margin-left: 10px;
}
.imageCancel {
  margin-top: 1em;
}
.reviewAuthorImage {
  border: none !important;
  border-radius: 0;
  border-bottom: 1px solid #a7a7a7 !important;
}
.reviewAuthorImg {
  width: 100px;
  border-radius: 100%;
  height: 100px;
}
.authorChangePicture {
  margin-top: 10px;
  background-color: #61a35d;
  color: white;
  text-align: center;
  width: 45%;
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  border: none;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitButton {
  background-color: var(--primary-purple);
  color: white;
  text-align: center;
  width: 45%;
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  border: none;
  width: 120px;
}
.submitButton:hover {
  background-color: #458240;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.reviewShowRating {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  max-width: 550px;
}
.reviewShowDescription {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  color: var(--text-body);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.reviewShowDescription1 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0rem;
  color: var(--text-body);
  line-height: 1.5rem;
  margin-top: 0rem;
}

.listingInfo {
  display: flex;
  align-items: center;
}
.listingContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 0.5rem;
  margin-top: 0.25rem;
}

.listingImg {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  border: 1px solid var(--primary-border);
}

.listingTitle {
  font-size: 14px;
  color: var(--text-body);
  font-weight: 400;
  font-family: "Editorial";
  line-height: 150%;
  margin-bottom: 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.seeMoreButton {
  width: fit-content;
  padding: 0rem;
  font-size: 14px;
  font-weight: 300;
  background: none;
  border: none;
  color: var(--text-body);
  text-decoration: underline;
}

.seeMoreButton:hover {
  color: var(--text-main);
  text-decoration: underline;
}
.reviewShowContainer {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  row-gap: 0.25rem;
}

.showMoreContainer {
  display: flex;
}

.showMore {
  color: var(--text-main);
  cursor: pointer;
  padding: 10px 15px;
  border: 1px solid var(--text-main);
}
.reviewShowProfile {
  display: flex;
  height: fit-content;
  justify-content: space-between;
  /* align-items: center; */
  /* flex-direction: column; */
  /* align-items: center; */
}
.reviewShowImg {
  width: 40px;
  object-fit: cover;
  border-radius: 100%;
  height: 40px;
}
.reviewSelect .MuiCheckbox-root {
  background: none;
  border-radius: 0;
  width: 100%;
}
.reviewPageHeader {
  font-size: 20px;
  font-weight: 600;
  font-family: "Editorial";

  margin-bottom: 0rem;
}

.reviewPageSub {
  font-size: 14px;
  color: var(--text-body);
}
.reviewCardParent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cardMain {
  width: 100%;
}

.rowGap {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.badge {
  background-color: white;
  color: var(--text-mute);
  border: 1px solid var(--primary-border);
  padding: 0px 10px;
  border-radius: 9999px;
  font-size: 12px;
}

.otherBadge {
  background-color: white;
  color: var(--text-mute);
  border: 1px solid var(--primary-border);
  padding: 0px 10px;
  border-radius: 9999px;
  font-size: 12px;
}

.showProfileData {
  display: flex;
  flex-direction: column;
}
.showProfileData p {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.profileName {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  font-size: 16px;
  margin-bottom: 0.2rem;
  font-weight: 600;
  text-transform: capitalize;
}

.time {
  font-size: 14px;
  color: #96a0a6;
}

.userReviewsSeperator {
  padding: 0 7%;
}

@media (max-width: 600px) {
  .reviewContainer {
  }

  .reviewPageHeader {
    font-size: 18px;
  }

  .cardMain {
    width: 100%;
  }
}
