.buttonStyles {
  display: flex;
  gap: 5px;
  border: none;
  padding: 10px 15px;
  text-align: center;
  border-radius: 0px;
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
  background-color: var(--primary-pink);
  color: var(--text-main);

  font-weight: 500;
  font-family: "Clean";
  font-size: 16px;
  white-space: nowrap;
  align-items: center;
  transition: all 0.3s ease-out;
}

.buttonStyles:hover {
  background-color: var(--primary-pink);
  transform: scale(1.02);
}

.buttonStyles:active {
  transform: scale(0.98);
}

/* PINK */

.pink {
  background-color: var(--primary-pink);
  color: var(--text-main);
}

.pink:hover {
  background-color: var(--primary-pink);
  color: var(--text-main);
  opacity: 0.8;
}

.secondaryPink {
  border: 1px solid var(--pink-primary);
  color: var(--text-main);
  background-color: transparent;
}

.secondaryPink:hover {
  opacity: 0.8;
}

/* PURPLE */

.purple {
  background-color: var(--primary-purple);
  color: white;
}

.purple:hover {
  opacity: 0.8;
  color: var(--purple-primary);
}

.secondaryPurple {
  border: 1px solid var(--purple-primary);
  background-color: transparent;
}

.secondaryPurple:hover {
  color: var(--purple-primary);
  opacity: 0.8;
}

/* disabled */
.disabled {
  opacity: 0.5; /* Dim the button for a disabled effect */
  background-color: #d7d4d4; /* Optional: a lighter gray or neutral color */
  color: var(--text-body); /* Optional: dim the text color */
  transform: none; /* Disable transform effects */
}

.disabled:hover,
.disabled:active {
  background-color: #f5f5f5; /* Ensure hover/active effects are ignored */
  color: var(--text-body); /* Maintain disabled color */
  transform: none; /* Prevent scaling or animations */
}
