.wrapper {
  border-top: 1px solid var(--primary-pink);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding-top: 50px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr;
    padding-top: 30px;
    gap: 30px;
  }
}
