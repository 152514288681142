.wrapper {
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid var(--primary-border);
  padding-bottom: 0.75rem;
}
