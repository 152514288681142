.wrapper {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  border-bottom: 1px solid var(--primary-border);
  padding: 0.75rem 0;
}

.container {
  max-width: var(--max-width);
  width: 100%;
}

.row {
  display: flex;

  align-items: center;
  gap: 0.5rem;
}

.badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  background-color: var(--primary-bg);
  color: var(--primary-text);
  font-size: 0.75rem;
  font-weight: 400;
  text-decoration: none;
  border: 1px solid var(--primary-bg);

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--primary-bg-light);
  }
}

.active {
  background-color: var(--primary-bg-dark);

  &:hover {
    background-color: var(--primary-bg-dark);
    opacity: 0.8;
  }
}

.icon {
  width: 10px;
  height: 10px;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0.5rem 0;
  }

  .row {
    padding: 0 1rem;
    overflow-x: auto;

    /* hide scroll bar */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .badge {
    padding: 0.25rem 0.5rem;
  }
}
